/* global window */

import { EnvironmentsTypes } from '~/types/util-types';

export const getEnv = (): EnvironmentsTypes => {
    let env = EnvironmentsTypes.Local;

    if (typeof window !== 'undefined' && window.ENV) {
        // testing window.ENV to make tests work
        env =
            window.ENV.ALLVIT_FRONTEND_ENV && window.ENV.ALLVIT_FRONTEND_ENV !== 'undefined'
                ? (window.ENV.ALLVIT_FRONTEND_ENV as EnvironmentsTypes)
                : EnvironmentsTypes.Local;
    }

    if (typeof process !== 'undefined' && process.env) {
        // to make the feature flags work in the remix backend
        env = (process.env.ALLVIT_FRONTEND_ENV as EnvironmentsTypes) || EnvironmentsTypes.Local;
    }

    return env;
};

export const featureTest = (environments: Array<string>) => {
    return environments.includes(getEnv());
};
