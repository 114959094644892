export type FrontendConfigType = {
    ENDPOINT_HOST: string;
    NODE_ENV: string;
    HUBSPOT_PORTAL_ID: string;
    CRYSTALLIZE_TENANT_IDENTIFIER: string;
    ALLVIT_FRONTEND_ENV: string;
};

export enum EnvironmentsTypes {
    Local = 'local',
    BetaTest = 'test-beta',
    BetaStage = 'stage-beta',
    BetaProd = 'prod-beta',
    Test = 'test',
    Stage = 'stage',
    Prod = 'prod',
}
